// 
//  User profile details.scss
//

// User profile details

.user-profile-sidebar {
    height: calc(100vh);
    width: 100%;
    background-color: $card-bg;

    // @media (max-width: 1199.98px) {
    //     position: fixed;
    //     right: 0;
    //     top: 0;
    //     z-index: 99;
    // }

    // @media (max-width: 575.98px) {
    //     min-width: 100%;
    // }
}
.custom-accordion {
    height: 100%;
    display: flex;
    flex-direction: column;

    .card-bm {
        margin-bottom: 2rem !important;
    }

}

.token-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .left {
        text-align: center;
    }

    .value {
        font-family: InterV;
        color: $gray-600;
        font-size: 24px;
        font-weight: bold;
        line-height: 29px;
    }
    .label {
        font-family: InterV;
        color: $gray-700;
        font-size: 12px;
        font-family: InterV;
        line-height: 15px;
    }

    .claim {
        width: 74px;
        height: 30px;
        background-color: $blue;
        border-radius: 4px;
        margin: 12px;
        border: 0;
        font-size: 13px;
        font-family: InterV;
        color: $gray-100 !important;
        line-height: 16px;
    }

    .detail {
        background-color: $gray-400;
        color: $gray-900 !important;
    }
}

.user-profile-desc {
    height: calc(100vh - 320px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 1.5rem;

    // @media (max-width: 991.98px) {
    //     height: calc(100vh - 324px);
    //     overflow-y: auto;
    // }
}

.supported-title,
.unsupported-title {
    border-bottom: 1px solid #eee;
}
.no-nft {
    text-align: center;
    margin: 1rem;
    color: #ddd;
}
.nft-detail,
.group-detail {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    cursor: pointer;

    .nft-img {
        width: 2.3rem;
        height: 2.3rem;
        margin-right: 1rem;
        border-radius: 50%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            background-color: $gray-300;
        }
    }
    
    .nft-text {
        display: flex;
        flex-direction: column;
        width: calc(100% - 3rem);

        .nftId {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 15px;
            font-family: InterV;
            color: $gray-600;
            line-height: 19px;
        }

        .nft-tokenId {
            width: 50%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            font-family: InterV;
            color: $gray-700;
            line-height: 17px;
        }
    }

    .nft-unsupported {
        margin-left: auto;
        width: 5.4rem;
        height: 1rem;
        background: $gray-400;
        border-radius: 0.5rem;
        text-align: center;
        font-size: 11px;
        font-family: InterV;
        color: $gray-600;
        line-height: 1rem;
    }

    .group-logo {
        flex: none;
        width: 2.3rem;
        height: 2.3rem;
        border-radius: 50%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
        }
    }

    .group-name {
        font-size: 14px;
        font-family: InterV;
        color: $gray-700;
        line-height: 17px;
        margin-left: 1rem;
    }

    .group-edit {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.8rem;
        height: 1.8rem;
        margin-left: auto;
        background: $gray-400;
        border-radius: 4px;
        font-size: 13px;
        font-family: Helvetica;
        color: $gray-900;
        line-height: 16px;
        cursor: pointer;
    }

    .nft-select {
        margin-left: auto;
        font-size: 1.3rem;
        
        .selected {
            color: $blue;
        }
    }
}
.nft-detail {
    padding: 0.5rem;
    border-radius: 5px;

    &:hover {
        background-color: $gray-200;
    }

    &.unsupported {
        opacity: 0.3;
    }
}
.profile-avatar {
    max-height: 70vh;
    overflow-y: overlay;
}
.nft-avatar-detail {
    display: inline-block;
    width: 24%;
    margin-right: 1.3%;
    margin-bottom: 1rem;
    cursor: pointer;

    &:nth-child(4n) {
        margin-right: 0;
    }

    .nft-img {
        width: 4rem;
        height: 4rem;
        margin: 0 auto 0.5rem;
        border-radius: 50%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .nft-text div {
        width: 100%;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        font-family: InterV;
        color: $gray-700;
    }
    .nft-text .nftId {
        font-size: 15px;
        font-family: InterV;
        color: $gray-600;
    }
}
.group-info{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;

    .nft-img {
        width: 2.3rem;
        height: 2.3rem;
        margin-right: 1rem;
        border-radius: 5px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            background-color: $gray-600;
        }
    }
    
    .nft-text {
        display: flex;
        flex-direction: column;
        width: calc(100% - 3rem);

        .nftId {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 15px;
            font-family: InterV;
            color: $gray-600;
            line-height: 19px;
        }

        .nft-tokenId {
            width: 50%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            font-family: InterV;
            color: $gray-700;
            line-height: 17px;
        }
    }

    .nft-text {
        width: auto;
    }
} 

.profile-avatar-wrap {
    position: relative;
    width: 8rem;
    margin-left: auto;
    margin-right: auto;

    img {
        display: inline-block;
    }

    .edit-avatar {
        position: absolute;
        left: 50%;
        top: 50%;
        display: none;
        width: 6rem;
        height: 6rem;
        transform: translate(-50%, -50%);
        border-radius: 3rem;
        text-align: center;
        line-height: 6rem;
        font-size: 2rem;
        background-color: rgba($gray-100, 0.5);
        color: $gray-400;
        cursor: pointer;
    }
    &:hover {
        .edit-avatar {
            display: inline-block;
        }
    }
}
.profile-nick {
    .current-nick {
        position: relative;
        cursor: pointer;
    }
    .edit-nick-input {
        width: 100%;
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: $gray-700;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid $gray-400;
        text-align: center;
        outline: none;
        border-radius: 0.25rem;
    }
    .edit-nick {
        position: absolute;
        top: 0;
        right: 0;
        color: $gray-500;
        transform: translateX(100%);
        padding-left: 0.5rem;
        cursor: pointer;
    }
}
.member-info-drawer {
    .ant-drawer-body {
        padding: 0;

        .nft-detail {
            display: inline-block;
            width: 33.3%;

            .nft-img {
                width: 4rem;
                height: 4rem;
                margin: 0 auto 0.5rem;
            }
            .nft-text, .nft-tokenId {
                width: 100%;
                text-align: center;
            }
        }
    }
}
.group-member-drawer {
    .ant-drawer-body {
        padding: 0;
    }

    .search-box {
        position: relative;

        .clear-icon {
            position: absolute;
            top: 13px;
            right: 20px;
            cursor: pointer;
        }
    }
}
.group-member-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;

    i {
        font-size: 1.25rem;
    }

    .count {
        display: flex;
        align-items: center;
        i {
            padding-right: 0.5rem;
        }
    }
}

.group-member-wrap {
    padding: 0 1.5rem;
    .group-member-item {
        display: flex;
        align-items: center;
        margin: 0.5rem 0;
    }
    .group-member-image {
        position: relative;
        margin-right: 1rem;
        .group-member-avatar {
            width: 2rem;
            height: 2rem;
            border-radius: 1rem;
        }

        .group-member-status {
            position: absolute;
            right: -1px;
            bottom: -1px;
            width: 0.8rem;
            height: 0.8rem;
            border: 2px solid $white;
            border-radius: 0.5rem;
            background-color: $gray-600;

            &.ONLINE {
                background-color: $green;
            }
        }
    }
}
.simplebar-content {
    padding-top: 10px !important;
}
.profile-button {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: auto;

    button {
        width: 100%;
    }
}

.general-setting-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    cursor: pointer;
}

.offcanvas-header {
    .btn-close {
        font-size: 1.5rem;
    }
}

.qr-code-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    margin: 10px 0;

    .reload-btn {
        height: 30px;
        line-height: 10px;
        margin-top: 15px;
        background-color: $gray-400;
        border: none;
        color: $gray-900 !important;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
    }
}

.invite-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    .img {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
    }
    .invite-title {
        margin: 10px 0;
        font-size: 15px;
        font-family: InterV_Semi-Bold, InterV_Semi;
        font-weight: bold;
        color: $gray-700;
        line-height: 19px;
    }
    .invite-desc {
        margin-bottom: 15px;
        text-align: center;
        font-size: 12px;
        font-family: InterV;
        color: $gray-600;
        line-height: 15px;
    }
    .invite-btn {
        width: 212px;
        height: 30px;
        background: $blue;
        border-radius: 4px;
        border: none;
        font-size: 14px;
        font-family: InterV;
        color: $gray-100;
        line-height: 17px;
    }
    .invite-link {
        margin-top: 5px;
        font-size: 14px;
        font-family: InterV;
        color: $gray-600;
        line-height: 17px;
        text-decoration: underline;
        cursor: pointer;
    }
    .invite-link-url {
        flex: 1;
        height: 30px;
        margin-bottom: 0.6rem;
        padding: 5px 8px;
        background: $gray-200;
        border-radius: 4px;
        font-size: 12px;
        font-family: InterV;
        color: $gray-800;
        line-height: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.invitation-link {
    word-break: break-all;

    .link {
        padding: 0 5px;
        color: $blue;
        text-decoration: underline;
    }

    i { cursor: pointer; }
}

.invitation-modal {
    width: 332px;
    padding: 0;
    border-radius: 4px;
    border: 0;
    overflow: hidden;

    .modal-content {
        position: relative;
        height: 450px;
        border: 0;
        border-radius: 4px;
        overflow: hidden;
    }

    .invitation-wrap {

        .ri-close-line {
            position: absolute;
            top: 7px;
            right: 15px;
            font-size: 20px;
            color: $gray-200;
            cursor: pointer;

            &.black {
                color: $gray-700;
            }
        }

        .invite-info {
            padding: 35px 20px 20px;
            background-color: $blue;

            .info {
                display: flex;
                margin-bottom: 32px;

                .img { 
                    width: 80px;
                    height: 80px; 
                    margin-right: 12px;
                }
                .title {
                    font-size: 15px;
                    font-family: InterV_Semi-Bold, InterV_Semi;
                    font-weight: bold;
                    color: $gray-100;
                    line-height: 19px;
                }
                .desc {
                    font-size: 12px;
                    font-family: InterV;
                    color: rgba(255,255,255,0.5);
                    line-height: 15px;
                }
            }
            .link {
                display: flex;
                align-items: center;

                .invite-link {
                    flex: 1;
                    height: 30px;
                    padding: 5px 8px;
                    background: rgba(255,255,255,0.2);
                    border-radius: 4px;
                    font-size: 12px;
                    font-family: InterV;
                    color: $gray-100;
                    line-height: 21px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .invite-btn {
                    width: 80px;
                    height: 30px;
                    padding-top: 0;
                    padding-bottom: 0;
                    margin-left: 12px;
                    background: $gray-100;
                    border-radius: 4px;
                    font-size: 14px;
                    font-family: InterV;
                    color: $blue !important;
                    line-height: 10px;
                }
            }
        }
        .invited-list {
            padding: 0 20px;
            .invited-title {
                margin-top: 15px;
                font-size: 12px;
                font-family: InterV;
                color: $gray-600;
                line-height: 15px;
            }

            .invited-item {
                display: flex;
                align-items: center;
                margin: 12px 0;

                .rank, .type {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: $gray-700;
                    line-height: 15px;
                }

                .avatar {
                    width: 24px;
                    height: 24px;
                    margin-left: 18px;
                    margin-right: 6px;
                    border-radius: 50%;
                    background: rgba(7,103,92,0.5);
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .count {
                    margin-left: auto;
                    font-size: 12px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: $blue;
                    line-height: 17px;
                }
            }
        }

        .qr-code-icon {
            position: absolute;
            top: 2rem;
            left: 43%;
            transform: translateX(-50%);
        }

        .qr-code-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 180px;
            // justify-content: center;
            margin: 50px 0 10px;

            .qr-code-block {
                width: 128px;
                height: 128px;
                background-color: $gray-200;
            }
        
            .reload-btn {
                height: 30px;
                line-height: 10px;
                margin-top: 15px;
                background-color: $gray-400;
                border: none;
                color: $gray-900 !important;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
            }
        }
    }
}
.select-avatar-modal {
    width: 332px;
    padding: 0;
    border-radius: 4px;
    border: 0;
    overflow: hidden;

    .select-avatar-wrap {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .avatar-lg {
            width: 8rem;
            height: 8rem;
        }

        .select-buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 1rem;

            button {
                width: 124px;
                height: 30px;
                margin-bottom: 1rem;
                background: $blue;
                border-radius: 4px;
                border-color: $blue;
                font-size: 13px;
                font-family: InterV;
                color: #FFFFFF !important;
                line-height: 16px;

                &.btn-reset {
                    background-color: $gray-400;
                    color: $gray-700 !important;
                    border-color: $gray-400;
                }
            }
        }
    }
}

.balance-modal {
    width: 332px;
    padding: 0;
    border-radius: 4px;
    border: 0;
    overflow: hidden;

    .modal-content {
        position: relative;
        height: 465px;
        border: 0;
        border-radius: 4px;
        overflow: hidden;
    }

    .balance-content {
        position: relative;
        display: flex;
        // justify-content: space-between;
        width: 100%;
        height: 176px;
        padding: 40px 18px 30px;
        background-color: $blue;
        background-image: url('../../../images/balance-bg.png');
        background-repeat: no-repeat;
        background-position: 0 0;

        .ri-close-line {
            position: absolute;
            top: 7px;
            right: 15px;
            font-size: 20px;
            color: $gray-200;
            cursor: pointer;
        }

        .content-left {
            width: 200px;
            .label {
                margin-top: 8px;
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: $blue;
                line-height: 14px;
            }
            .count {
                margin: 12px 0;
                font-size: 14px;
                font-family: InterV_Semi;
                font-weight: bold;
                color: $blue;
                line-height: 17px;

                .claimable {
                    font-size: 23px;
                    line-height: 29px;
                }
            }

            .claim-btn {
                width: 100px;
                height: 24px;
                border-radius: 6px;
                border: 1px solid $blue;
                background-color: transparent;
                font-size: 12px;
                font-family: InterV;
                color: $blue !important;
                line-height: 10px;
            }
        }

        .content-right {
            .item {
                margin: 12px 0;
            }
            .label {
                margin-bottom: 5px;
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: $gray-100;
                line-height: 14px;
            }
            .value {
                font-size: 14px;
                font-family: InterV_Semi;
                font-weight: bold;
                color: $gray-100;
                line-height: 17px;

                .unit {
                    margin-left: 3px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: $gray-100;
                    line-height: 17px;
                }
            }
        }
    }
    .balance-signer {
        position: absolute;
        left: 20px;
        top: 146px;
        width: 292px;
        height: 116px;
        padding: 12px 7px;
        background: $gray-100;
        box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.15);
        border-radius: 8px;
        box-sizing: border-box;
        text-align: center;

        .sign-days {
            display: flex;
            margin-bottom: 20px;

            .day-item {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                width: 36px;
                height: 40px;
                margin-left: 4px;
                background: rgba(252,201,58,0.2);
                border-radius: 4px;
                font-size: 10px;
                font-family: InterV_Medium;
                color: #E7863A;
                line-height: 12px;

                .icon {
                    font-size: 16px;
                    padding-top: 3px;
                }

                &.before {
                    background-color: transparent;
                    color: rgba(#B7B7B7, 0.5);
                    border: 1px solid rgba(#B7B7B7, 0.5);
                }

                &.today {
                    background: #FCC93A;
                    color: $gray-100;
                }

                &:first-child {
                    margin-left: 0;
                }
            }
        }
        .sign-btn {
            width: 195px;
            height: 30px;
            background: #E7863A;
            border-radius: 8px;
            border: 1px solid #E7863A;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: $gray-100;
            line-height: 17px;
        }
    }
    .balance-record {
        padding: 20px;

        .record-title {
            font-size: 12px;
            font-family: InterV;
            color: $gray-600;
            line-height: 15px;
        }

        .record-item {
            display: flex;
            align-items: center;
            margin-top: 12px;

            

            .record-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 35px;
                height: 35px;
                margin-right: 16px;
                background: rgba(7,103,92,0.5);
                color: $gray-100;
                font-size: 18px;
                border-radius: 50%;
            }

            .record-info {
                .type {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: $gray-700;
                    line-height: 17px;
                }
                .time {
                    font-size: 12px;
                    font-family: InterV;
                    color: $gray-600;
                    line-height: 15px;
                }
            }

            .record-count {
                margin-left: auto;
                font-size: 14px;
                font-family: InterV_Medium;
                color: $blue;
                line-height: 17px;
            }

            &:first-child {
                margin-top: 0;
            }
        }
    }
}
.ant-drawer-header-title {
    flex-direction: row-reverse;
}
.ant-drawer-header {
    border-bottom: 1px solid $gray-400;
}
.ant-drawer-title {
    color: $gray-800;
}
.ant-drawer-close {
    color: rgba($gray-800, 0.5);
}
.ant-drawer-content, .modal-content {
    background-color: $gray-200;
}
.sign-modal {
    width: 332px;
    height: 400px;
    min-height: 400px;
    border-radius: 4px;
    border: 0;
    padding: 0;
    overflow: hidden;
    background-color: $gray-100;

    .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        margin: 0 auto;
        padding: 38px 0 19px;
        background-image: url('../../../images/stickers/confirm_bg.png');
        background-repeat: no-repeat;

        .ri-close-line {
            position: absolute;
            top: 7px;
            right: 15px;
            font-size: 20px;
            color: $gray-700;
            cursor: pointer;
        }
        .img {
            width: 180px;
        }
        .text {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .title {
            font-size: 18px;
            font-family: InterV_Medium;
            color: $gray-700;
            line-height: 21px;
            padding-bottom: 5px;
            margin-bottom: 8px;
        }
        .desc {
            text-align: center;
            font-size: 12px;
            font-family: InterV;
            color: $gray-600;
            line-height: 15px;
        }
        .btn {
            width: 293px;
            height: 45px;
            margin: 1rem 0;
            background: $blue;
            border-radius: 4px;
            font-size: 15px;
            font-family: InterV;
            color: $gray-100;
            line-height: 19px;
            border: 1px solid $blue;
        }
        .no-confirm {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-family: InterV;
            color: $gray-600;
            line-height: 15px;
            cursor: pointer;

            .checkbox {
                margin: 0 0.25rem;
            }
        }
    }
}

.verified-status {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-family: InterV;
    color: $gray-500;

    img {
        margin-right: 5px;
    }

    .active {
        color: $gray-600;
    }
}

.social-media {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    .connect {
        display: flex;
        align-items: center;
        height: 30px;
        border-radius: 4px;
        border: 0;
        background-color: $blue;
        font-size: 13px;
        font-family: InterV;
        color: #FFFFFF !important;
        line-height: 16px;

        i {
            font-size: 15px;
            margin-right: 5px;
        }
    }

    .twitter {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        padding: 0 10px;
        background: #1DA1F1;
        border-radius: 15px;
        font-size: 12px;
        font-family: InterV;
        color: #F5F7FB;
        cursor: pointer;

        i {
            font-size: 14px;
            margin-right: 3px;
        }
    }

    .media {
        margin: 0 10px;
        cursor: pointer;
        font-size: 20px;
        color: $gray-500;

        &.active {
            color: $blue;
        }
    }
}

.social-media-tips {
    position: relative;
    margin-top: 12px;

    .decorate-arrow {
        position: absolute;
        left: calc(50% - 6px);
        top: -12px;
        width: 12px;
        height: 12px;
        border: 6px solid $card-bg;
        border-top-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
    }
    .btn-close {
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .tips-content {
        padding: 20px;
        background-color: $card-bg;
        border-radius: 8px;
    }
    .tips-title {
        margin-top: 12px;
        margin-bottom: 8px;
        font-size: 15px;
        font-family: InterV_Semi-Bold, InterV_Semi;
        font-weight: bold;
        color: $gray-700;
    }
    .tips-desc {
        font-size: 12px;
        font-family: InterV;
        color: $gray-600;
        line-height: 15px;

        .underline {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.group-detail-info {
    .ant-drawer-body {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.group-detail-logo {
    position: relative;
    margin: 10px auto 20px;

    .group-type {
        position: absolute;
        right: 0;
        bottom: 0;
    }
}
.group-detail-name {
    font-size: 16px;
    font-family: InterV;
    color: $gray-700;
    line-height: 20px;
    margin: 0 auto 20px;
}
.group-detail-desc {
    width: 100%;
    padding: 25px;
    border-bottom: 1px solid $gray-300;
    margin-bottom: 25px;
}
.group-detail-nft {
    width: 100%;
    padding: 0 25px 25px;

    .nft-logo {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        .logo {
            width: 38px;
            height: 38px;
            border-radius: 5px;
        }
        .name {
            padding-left: 10px;
            font-size: 16px;
            font-family: InterV;
            color: $gray-700;
            line-height: 20px;
        }
    }
    .marketplace {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        background: $gray-100;
        border-radius: 4px;
        border: 1px solid $gray-300;

        .mp-item {
            display: flex;
            flex-direction: column;
            align-items: center;

            .value {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-family: InterV_Semi;
                font-weight: bold;
                color: $gray-600;
                line-height: 20px;
                
                img {
                    height: 15px;
                    margin-right: 3px;
                }
            }
            .label {
                font-size: 12px;
                font-family: InterV;
                color: $gray-600;
                line-height: 15px;
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.nft-list-wrap {
    .content {
        position: relative;
        min-height: 4rem;
        overflow: hidden;

        .nft-detail {
            display: inline-block;
            width: 33.3%;

            .nft-img {
                width: 4rem;
                height: 4rem;
                margin: 0 auto 0.5rem;
            }
            .nft-text, .nft-tokenId {
                width: 100%;
                text-align: center;
            }
        }
    }
    
    .nft-loading {
        z-index: 9999;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        .chat-loading-icon {
            animation: loading-360 2s infinite linear;
        }
    }
}
.crop-avatar-modal {
    padding: 0;
    border-radius: 4px;
    border: 0;
    overflow: hidden;

    .crop-avatar-wrap {
        display: flex;

        .content {
            width: 300px;
            height: 300px;
            margin-right: 1rem;
            background-color: $gray-600;
        }

        .preview {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;

            .img-preview {
                width: 7rem;
                height: 7rem;
                overflow: hidden;
                margin-bottom: 1rem;
                background-color: $gray-600;
            }
            .radius {
                border-radius: 50%;
            }
            .crop {
                width: 124px;
                height: 30px;
                margin-top: auto;
                background: $blue;
                border-radius: 4px;
                border-color: $blue;
                font-size: 13px;
                font-family: InterV;
                color: #FFFFFF !important;
                line-height: 16px;
            }
        }
    }
}

.profile-nfts {
    max-height: 300px;
    overflow-y: auto;

    .nft-detail {
        display: inline-block;
        width: 33.3%;

        .nft-img {
            width: 4rem;
            height: 4rem;
            margin: 0 auto 0.5rem;
        }
        .nft-text, .nft-tokenId {
            width: 100%;
            text-align: center;
        }
    }
}

@keyframes loading-360 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
