// 
// authentication.scss
//

.auth-logo{
    .logo{
        margin: 0px auto;
    }

    .logo-dark{
        display: $display-block;
    }

    .logo-light{
        display: $display-none;
    }
}

.login-wallets {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    // width: 445px;
    // height: 300px;
    padding: 20px;
    // overflow-y: scroll;

    .wallet {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90px;
        height: 90px;
        padding: 9px;
        margin-right: 10px;
        margin-bottom: 10px;
        background: $sidebar-menu-item-active-bg;
        border-radius: 8px;
        cursor: pointer;

        &:nth-child(4n) {
            margin-right: 0;
        }

        .wallet-logo {
            width: 50px;
            height: 50px;
            border-radius: 25px;
        }

        .wallet-name {
            font-size: 10px;
            font-family: InterV;
            color: $gray-600;
            line-height: 24px;
            white-space: nowrap;
        }
    }
}

.connect-btn {
    width: 340px !important;
    height: 45px;
    margin: 15px 20px;
    border-radius: 4px;
    color: $white;
}

