
// 
// Sidemenu
// 



.side-menu{
    width: 100%;
    height: 80px;
    background-color: $sidebar-bg;
    display: flex;
    z-index: 9;
    box-shadow: 2px 2px 4px rgba(15,34,58,.12);
    justify-content: space-between;
    padding: 0 8px;
    margin-bottom: 2px;

    .btn-list {
        display: flex;
    }

    .logo-sm img {
        background-color: $blue;
        border-radius: 5px;
        margin-right: 10px;
    }

    button {
        outline: none;
    }

    .header-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 56px;
        margin-top: 12px;
        margin-right: 12px;
        background: $gray-200;
        border-radius: 8px;
        font-size: 18px;
        font-family: Inter-SemiBold;
        font-weight: bold;
        color: $gray-600;
        line-height: 19px;
        cursor: pointer;

        .icon {
            height: 25px;
            font-size: 24px;
            padding-top: 0.2rem;
            padding-right: 0.5rem;
        }
        .avatar {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 0.5rem;
        }

        .token {
            font-size: 12px;
        }

        .num {
            margin-right: 5px;
        }
        .connect {
            padding-left: 0.3rem;
        }
    }

    // @media (max-width: 991.98px) {
    //     position: fixed;
    //     bottom: 0;
    //     height: 60px;
    //     min-width: 100%;
    //     min-height: auto;
    //     display: block;
    //     border-top: 1px solid $border-color;
    // }

    .navbar-brand-box{
        text-align: center;


        // @media (max-width: 991.98px) {
        //     display: none;
        // }

        .logo{
            line-height: 70px;
            padding: 0 10px;
        }

        .logo-dark{
            display: $display-block;
        }

        .logo-light{
            display: $display-none;
        }
    }

    .theme-mode-icon {
        &:before {
            content: $theme-mode-icon;
        }
    }
}

.side-menu-nav{

    .nav-item{
        margin: 7px 0;
        // @media (max-width: 991.98px) {
        //     flex-basis: 0;
        //     flex-grow: 1;
        //     margin: 5px 0;
        // }
        .nav-link{
            text-align: center;
            font-size: 24px;
            color: $sidebar-menu-item-color;
            width: 56px;
            height: 56px;
            line-height: 56px;
            margin: 0px auto;
            border-radius: 8px;
            padding: 0;


            // @media (max-width: 991.98px) {
            //     font-size: 20px;
            //     width: 48px;
            //     height: 48px;
            //     line-height: 48px;
            // }

            &.active{
                background-color: $sidebar-menu-item-active-bg;
                color: $primary;
            }
        }

        &.show>.nav-link{
            background-color: $sidebar-menu-item-active-bg;
            color: $primary;
        }
    }

    .profile-user {
        height: 36px;
        width: 36px;
        background-color: $gray-300;
        padding: 3px;
    }
}



