//
// Google font - Public Sans
//

@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

// interV
@font-face {
  font-family: 'InterV';
  src: url('../../../fonts/Inter-V.ttf');
}
@font-face {
  font-family: 'InterV_Medium';
  src: url('../../../fonts/Inter-Medium.ttf');
}
@font-face {
  font-family: 'InterV_Semi';
  src: url('../../../fonts/Inter-SemiBold.ttf');
}
@font-face {
  font-family: 'Inter_Semi';
  src: url('../../../fonts/Inter-SemiBold.ttf');
}
@font-face {
  font-family: 'Inter-Bold';
  src: url('../../../fonts/Inter-Bold.ttf');
}
@font-face {
  font-family: 'Inter-Black';
  src: url('../../../fonts/Inter-Black.ttf');
}
@font-face {
  font-family: 'Inter-ExtraBold';
  src: url('../../../fonts/Inter-ExtraBold.ttf');
}
@font-face {
  font-family: 'Inter-ExtraLight';
  src: url('../../../fonts/Inter-ExtraLight.ttf');
}
@font-face {
  font-family: 'Inter-Light';
  src: url('../../../fonts/Inter-Light.ttf');
}
@font-face {
  font-family: 'Inter-Medium';
  src: url('../../../fonts/Inter-Medium.ttf');
}
@font-face {
  font-family: 'Inter-Regular';
  src: url('../../../fonts/Inter-Regular.ttf');
}
@font-face {
  font-family: 'Inter-SemiBold';
  src: url('../../../fonts/Inter-SemiBold.ttf');
}
@font-face {
  font-family: 'Inter-Thin';
  src: url('../../../fonts/Inter-Thin.ttf');
}
@font-face {
  font-family: 'Inter';
  src: url('../../../fonts/Inter-Regular.ttf');
}