.landing {
    z-index: 1;
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    background: #f7f7ff;
    overflow: hidden;

    &-container {
        z-index: 1000;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 1230px;
        // height: calc(100% - 80px);
        margin: 40px auto;
    }

    &-header {
        display: flex;
        justify-content: space-between;

        .media-list {
            display: flex;
            margin-top: 20px;
        }

        .media {
            margin-right: 40px;
            cursor: pointer;

            img {
                width: 36px;
                height: 36px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &-logo {
        display: flex;
        align-items: center;
        font-size: 21px;
        font-family: InterV_Semi;
        font-weight: bold;
        color: #ffffff;
        line-height: 25px;

        .logo-image {
            width: 9rem;
        }
    }

    &-icon {
        width: 25px;
        height: 25px;
        margin-right: 10px;
        border-radius: 17px;
        background: #fff;
    }

    &-link {
        cursor: pointer;
        margin-left: 59px;
        font-size: 14px;
        font-family: Inter_Medium;
        color: #495057;
        line-height: 24px;
    }

    &-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 100px 0;

        .image {
            position: relative;
            height: 472px;
        }

        .text {
            width: 540px;

            .top-title {
                margin-bottom: 10px;
                font-size: 16px;
                font-family: Inter_Bold;
                color: #495057;
                line-height: 20px;
            }

            .title {
                margin-bottom: 20px;
                font-size: 50px;
                font-family: Inter;
                color: #495057;
                line-height: 66px;

                .emphasize {
                    padding-left: 20px;
                    font-family: Inter-Bold;
                    font-weight: bold;
                }
            }

            .desc {
                margin-bottom: 30px;
                font-size: 18px;
                font-family: Inter;
                color: #495057;
                line-height: 21px;
                opacity: 0.5;
            }
        }

        .btn {
            width: 300px;
            height: 60px;
            margin-top: 20px;
            background: #f7f7ff !important;
            border-radius: 4px !important;
            font-size: 20px !important;
            font-family: InterV_Medium;
            color: #7269ef !important;
            border: 2px solid #7269ef !important;
        }
    }

    &-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 190px;
        background: #7269ef;
        border-radius: 24px 24px 0px 0px;

        .media-list {
            display: flex;
            margin-top: 20px;
        }

        .landing-link {
            font-size: 14px;
            font-family: Inter_Medium;
            color: #ffffff;
            line-height: 19px;
        }

        .logo {
            .logo-image {
                width: 280px;
                height: 80px;
            }
        }

        .media {
            margin-right: 40px;
            cursor: pointer;

            img {
                width: 36px;
                height: 36px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .pic-level {
        z-index: 100;
        position: absolute;
    }

    .level1 {
        right: 0;
        bottom: 62px;
        width: 771px;
        height: 644px;
    }

    .level3 {
        bottom: 167px;
        right: 270px;
        width: 366px;
        height: 289px;
    }

    .level2 {
        bottom: -5px;
        right: 267px;
        width: 285px;
        height: 420px;
        animation: shake 1.5s ease-in-out infinite alternate;
        transform: rotate(-5deg) translate(-10px, 5px);
    }
}

.wallet-modal {
    width: 380px !important;
}

.first-screen {
    z-index: 1;
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 796px;
    background-image: url("../../../images/landing/bg1.jpeg");
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;

    .landing-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 1230px;
        height: calc(100% - 80px);
        margin: 40px auto;
    }

    .landing-bg2 {
        z-index: 10;
        position: absolute;
        top: 0;
        right: 0;
        width: 800px;
        height: 789px;
        background-image: url("../../../images/landing/bg2.png");
        background-position: 0 0;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .landing-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .landing-logo {
        display: flex;
        align-items: center;
        font-size: 21px;
        font-family: InterV_Semi;
        font-weight: bold;
        color: #ffffff;
        line-height: 25px;

        .logo-image {
            width: 12rem;
        }
    }
    .landing-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 550px;
        font-size: 60px;
        font-family: InterV_Semi;
        font-weight: bold;
        color: #ffffff;
        line-height: 72px;

        .btn {
            width: 300px;
            height: 60px;
            margin-top: 20px;
            background: #f7f7ff;
            border-radius: 5px !important;
            font-size: 20px !important;
            font-family: InterV_Medium;
            color: #7269ef !important;
        }
    }
}

.animated-1 {
    position: absolute;
    left: 347px;
    top: 200px;

    .dot {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 5px;
        border-radius: 50%;
        background-color: #fff;

        &:nth-child(1) {
            animation: dotFade1 2s ease infinite;
        }
        &:nth-child(2) {
            animation: dotFade2 2s ease infinite;
        }
        &:nth-child(3) {
            animation: dotFade3 2s ease infinite;
        }
    }
}

.animated-2 {
    position: absolute;
    left: 320px;
    top: 220px;

    .line {
        width: 72px;
        height: 4px;
        margin-bottom: 3px;
        border-radius: 3px;
        background-color: #fff;
        animation: grow1 2s 0s ease infinite;

        &:nth-child(2) {
            width: 61px;
            animation: grow2 2s ease infinite;
        }

        &:nth-child(3) {
            width: 50px;
            animation: grow3 2s ease infinite;
        }
    }
}

.animated-3 {
    position: absolute;
    top: 256px;
    left: 407px;
    animation: bounce 2.8s ease infinite;
}

@media (min-width: 1500px) {
    .first-screen {
        background-image: url("../../../images/landing/bg.png");
    }
}

@keyframes grow1 {
    0% {
        width: 0;
        margin-left: -3px;
    }
    100% {
        width: 72px;
        margin-left: 0;
    }
}
@keyframes grow2 {
    0% {
        width: 0;
        margin-left: -6px;
    }
    10% {
        width: 0;
        margin-left: -6px;
    }
    100% {
        width: 61px;
        margin-left: 0;
    }
}
@keyframes grow3 {
    0% {
        width: 0;
        margin-left: -9px;
    }
    20% {
        width: 0;
        margin-left: -9px;
    }
    100% {
        width: 50px;
        margin-left: 0;
    }
}

@keyframes dotFade1 {
    0% {
        background-color: transparent;
    }
    25% {
        background-color: #fff;
    }
    50% {
        background-color: #fff;
    }
    75% {
        background-color: #fff;
    }
    100% {
        background-color: transparent;
    }
}
@keyframes dotFade2 {
    0% {
        background-color: transparent;
    }
    25% {
        background-color: transparent;
    }
    50% {
        background-color: #fff;
    }
    75% {
        background-color: #fff;
    }
    100% {
        background-color: transparent;
    }
}
@keyframes dotFade3 {
    0% {
        background-color: transparent;
    }
    25% {
        background-color: transparent;
    }
    50% {
        background-color: transparent;
    }
    75% {
        background-color: #fff;
    }
    100% {
        background-color: transparent;
    }
}

@keyframes bounce {
    0% {
        top: 256px;
    }
    25% {
        top: 250px;
    }
    50% {
        top: 256px;
    }
    75% {
        top: 250px;
    }
    100% {
        top: 256px;
    }
}

@keyframes shake {
    0% {
        bottom: -5px;
        transform: rotate(-5deg) translate(-10px, 5px);
    }
    33% {
        bottom: 0px;
    }
    100% {
        bottom: -8px;
        transform: rotate(13deg) translate(40px, 10px);
    }
}
