// 
// user chat.scss
//

// user chat
.user-chat {
    background-color: $card-bg;
    box-shadow: 0 2px 4px rgba(15,34,58,.12);
    transition: all 0.4s;
    height: calc(100vh - 78px);
    overflow: hidden;
    // @media (max-width: 991.98px) {
    //     position: fixed;
    //     left: 0;
    //     top: 0;
    //     width: 100%;
    //     height: 100%;
    //     visibility: hidden;
    //     transform: translateX(100%);
    //     z-index: 99;

    //     &.user-chat-show{
    //         visibility: visible;
    //         transform: translateX(0);
    //     }
    // }

}
.group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
}
.group-header-logo-wrap {
    position: relative;

    .group-type {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}
.group-info-wrap {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .marketplace {
        display: flex;
        padding-left: 30px;
        border-left: 1px solid $gray-400;
        margin-left: 30px;

        .item {
            display: flex;
            flex-direction: column;
            margin-right: 32px;

            .label {
                font-size: 12px;
                font-family: InterV;
                color: $gray-500;
                line-height: 15px;
            }
            .value {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-family: InterV_Semi;
                font-weight: bold;
                color: $gray-600;
                line-height: 20px;
                
                img {
                    height: 15px;
                    margin-right: 3px;
                }
            }
        }
    }

    .right-btn {
        margin-left: auto;
    }
}
.profile-user-dropdown {

    .nav-link {
        padding: 0;
        color: $gray-600;
    }

    .dropdown-item {
        display: flex;
        align-items: center;

        i {
            font-size: 18px;
            padding: 0 5px;
        }
    }

    .logout {
        color: $red;
    }
}

.user-chat-nav {
    .nav-btn {
        height: 30px;
        width: 30px;
        line-height: 30px;
        box-shadow: none;
        padding: 0;
        font-size: 20px;
        color: $gray-600;
        background-color: rgba($gray-100, 0.7);
    }
    // @media (max-width:575.98px) {
    //     display: flex;
    //     justify-content: flex-end;
    // }
}

.chat-conversation {
    li {
        clear: both;
        // overflow: hidden;
    }

    .skeleton {
        margin-bottom: 1.5rem;
    }

    .chat-avatar {
        
        margin: 0 1rem 0 0/*rtl:0 0 0 16px*/;
        
        img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
        }
    }

    .chat-day-title {
        position: relative;
        text-align: center;
        margin-bottom: 24px;
        margin-top: 12px;

        .title {
            background-color: $border-color;
            position: relative;
            font-size: 13px;
            z-index: 1;
            padding: 6px 12px;
            border-radius: 5px;
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            left: 0;
            right: 0;
            background-color: $border-color;
            top: 10px;
        }

        .badge {
            font-size: 12px;
        }
    }

    .conversation-list {
        display: inline-flex;
        position: relative;
        align-items: flex-start;
        margin-bottom: 0.5rem;
        max-width: 70%;

        .user-chat-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .send-failed {
            z-index: 9999;
            position: absolute;
            bottom: 5px;
            right: 0;
            width: 20px;
            height: 20px;
            font-size: 20px;
            transform: translate(100%, 0);
            color: $red;
            cursor: pointer;
        }

        .chat-shortcut {
            z-index: 999;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            transform: translateX(100%);
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .chat-shortcut-emoji {
                position: absolute;
                top: -17px;
                left: 25px;
                background-color: #fff;
                width: 85px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: space-around;
                padding: 0 10px;
                transform: translateY(52%);
                box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2000);
                border-radius: 21px;

                .emoji {
                    display: inline-block;
                    width: 28px;
                    cursor: pointer;

                    img {
                        width: 100%;
                    }
                }
            }

            .dropdown {
                position: relative;
                top: 2px;
                left: 0;
                display: inline-block;
                padding: 0 0.3rem;

                i {
                    color: $blue;
                }
            }

            .message-operations,
            .chat-shortcut-emoji {
                display: none;
            }
        }

        .message-sticked-emoji {
            display: flex;
            flex-direction: row;
            margin-bottom: 0.5rem;

            .sticked-emoji-wrap {
                padding: 0 0.5rem;
                margin-right: 0.5rem;
                border-radius: 0.5rem;
                background-color: rgba($blue, 0.2);
                border: 1px solid rgba($blue, 0.8);
                cursor: pointer;

                .emoji {
                    display: inline-block;
                    width: 20px;
                    margin-right: 0.4rem;

                    img {
                        width: 100%;
                    }
                }

                .count {
                    font-size: 14px;
                }
            }
        }

        .ctext-wrap {
            display: flex;
            margin-bottom: 0.5rem;
            padding-right: 0.2rem;
            position: relative;
        }

        .quote-wrap {
            display: flex;
            max-width: 25rem;
            margin-bottom: 0.5rem;
            padding: 0.1rem 0.5rem;
            background-color: $gray-400;
            border-radius: 0.2rem;

            .quote-msg {
                font-size: 12px;
                color: $gray-600;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .quote-msg-image {
                height: 2rem;
                margin-left: 0.25rem;
                border-radius: 0.2rem;
            }
        }

        .ctext-wrap-content {
            order: 2;
            padding: 0.75rem 1.25rem;
            background-color: $chat-primary-bg;
            border-radius: 0px 0.5rem 0.5rem 0.5rem;
            color: $white;
            font-size: 1rem;
            position: relative;

            .mb-0 {
                text-align: left;
                white-space: pre-wrap;
            }

            .respect-wrap {
                position: absolute;
                bottom: 0;
                right: 0;
                transform: translate(35%, 35%);
                cursor: pointer;

                .gif {
                    width: 60px;
                }

                .fire {
                    width: 21px;
                    position: absolute;
                    right: 0;
                    bottom: 8px;

                    .count {
                        position: absolute;
                        left: 50%;
                        top: 65%;
                        transform: translate(-50%, -50%);
                        font-size: 12px;
                        font-family: InterV_Semi;
                        font-weight: bold;
                        color: #FFFFFF;
                        line-height: 15px;
                    }
                }

                img {
                    width: 100%;
                }
            }

            &:before {
                content: "";
                position: absolute;
                border: 5px solid transparent;
                border-right-color: $chat-primary-bg;
                border-top-color: $chat-primary-bg;                
                top: 0/*rtl:auto*/;                
                // bottom: auto/*rtl:0*/;
                left: -10px;
            }
            
            .url-message-card {
                margin: 0;
                text-align: left;
                
                .url-address {
                    color: $white;
                }

                .url-meta {
                    margin-top: 0.6rem;
                    padding-left: 1rem;
                    border-left: 2px solid $white;
                    font-size: 0.8rem;

                    .url-origin {
                        color: $white;
                        font-size: 1rem;
                        font-weight: 700;
                    }

                    .url-title {
                        font-size: 1rem;
                        font-weight: 700;
                    }
                }
                .url-cover {
                    width: 100%;

                    img {
                        width: 100%;
                    }
                }
            }
        }

        .has-respect .ctext-wrap-content {
            padding: 0.75rem 1.6rem 1.25rem 1.25rem;
            background: linear-gradient(135deg, #7269EF 0%, #E7863A 100%);
        }
        
        .imageMessage-wrap {

            .ctext-wrap-content {
                padding: 0;
            }
        }

        .stickerMessage-wrap {
            padding: 0;
            .ctext-wrap-content {
                background-color: transparent !important;
                padding: 0;

                &:before {
                    border-right-color: transparent !important;
                    border-top-color: transparent !important;
                }
            }
        }

        .conversation-name {
            height: 1rem;
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            align-items: center;
            font-weight: $font-weight-medium;
            font-size: 14px;
            margin-bottom: 0.3rem;
        }

        .dropdown {
            .dropdown-toggle {
                font-size: 18px;
                padding: 4px;
                color: $gray-600;

                // @media (max-width: 575.98px) {
                //     display: none;
                // }
            }

        }

        .chat-time {
            display: none;
            align-items: center;
            font-size: 12px;
            margin: 0 0.4rem;
            text-align: right;
            color: $gray-600;
        }

        .message-img {
            border-radius: .2rem;
            position: relative;
            text-align: center;

            .image-loading {
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 4rem;
                width: 4rem;

                .chat-loading-icon {
                    animation: loading-360 2s infinite linear;
                }
            }

            .message-img-list {
                position: relative;
            }

            .upload-failed {
                z-index: 9999;
                position: absolute;
                right: -1rem;
                top: 50%;
                color: $red;
                cursor: pointer;
            }

            img {
                max-width: 10rem;
                max-height: 10rem;
                min-height: 4rem;
            }

            .message-img-link {
                position: absolute;
                right: 10px/*rtl:auto*/;
                left: auto/*rtl:0*/;
                bottom: 10px;

                li {
                    >a {
                        font-size: 18px;
                        color: $white;
                        display: inline-block;
                        line-height: 30px;
                        width: 30px;
                        height: 30px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .right {
        clear: both;

        &::after {
            content: '.';
            height: 0;
            opacity: 0;
            display: block;
            clear: both;
        }

        .chat-avatar {
            order: 3;
            margin-right: 0px;
            margin-left: 1rem;
        }

        .chat-time {
            text-align: left;
            color: $gray-600;
        }

        .conversation-list {
            display: inline-flex;
            // position: relative;
            // align-items: flex-end;
            float: right;
            text-align: right;
            max-width: 70%;

            .user-chat-content {
                align-items: flex-end;
            }

            .conversation-name {
                flex-direction: row-reverse;
            }

            .message-operations .ri-more-2-fill {
                right: auto;
                left: -1rem;
            }

            .message-sticked-emoji {
                flex-direction: row-reverse;

                .sticked-emoji-wrap:first-child {
                    margin: 0;
                }
            }

            .send-failed {
                left: 0;
                transform: translate(-100%, 0);
            }

            .chat-shortcut {
                position: absolute;
                top: 0;
                left: 0;
                width: 24px;
                transform: translateX(-100%);
                // align-items: flex-end;
                flex-direction: column;

                .message-operations {
                    // margin-left: auto;
                }

                .chat-shortcut-emoji {
                    left: auto;
                    right: 25px;
                    display: flex;
                    flex-direction: row-reverse;
                    display: none;
                }
            }

            .ctext-wrap {
                justify-content: flex-end;
                padding-right: 0;
                padding-left: 0.2rem;

                .ctext-wrap-content {
                    order: 2;
                    background-color: $chat-secondary-bg;
                    color: $dark;
                    text-align: right;
                    border-radius: 8px 0px 8px 8px;
                    
                    .respect-wrap {
                        right: auto;
                        left: 0;
                        transform: translate(-35%, 35%);

                        .fire {
                            right: auto;
                            left: 2px;
                        }
                    }

                    .urlMessage {
                        text-align: left;
                    }

                    &:before {
                        border: 5px solid transparent;
                        border-top-color: $chat-secondary-bg;
                        border-left-color: $chat-secondary-bg;
                        top: 0;
                        left: auto;
                        right: -10px;
                    }

                    .url-message-card {
                        margin: 0;
                        text-align: left;
                        
                        .url-address {
                            color: $blue;
                        }
        
                        .url-meta {
                            margin-top: 0.6rem;
                            padding-left: 1rem;
                            border-left: 2px solid $blue;
                            font-size: 0.8rem;
        
                            .url-origin {
                                color: $blue;
                                font-size: 1rem;
                                font-weight: 700;
                            }
        
                            .url-title {
                                font-size: 1rem;
                                font-weight: 700;
                            }
                        }
                        .url-cover {
                            width: 100%;
        
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .has-respect .ctext-wrap-content {
                background: linear-gradient(45deg, #FFBE8A 0%, #F5F7FB 100%);
            }

            .has-respect .textMessage-wrap .ctext-wrap-content {
                padding-right: 1.25rem;
                padding-left: 1.6rem;
            }

            .message-img {
                .upload-failed {
                    right: auto;
                    left: -1rem;
                    top: 50%;
                }
            }
        }

        .dot {
            background-color: $dark;
        }
    }
}

.clear-both {
    &::after, &::before {
        content: '';
        display: table;
        clear: both;
    }
}

.chat-conversation {
    height: calc(100vh - 16rem);
    max-height: 100%;
    overflow: hidden;

    &::-webkit-scrollbar {
        height: 0;
        width: 6px;
        opacity: 0;
    }

    &::-webkit-scrollbar-track{
        border: 0;
    }

    &::-webkit-scrollbar-track-piece {
        opacity: 0;
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        opacity: 0;
        width: 5px;
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
        -webkit-transition: .2s ease-in-out;
        transition: .2s ease-in-out;
    }

    &:hover, &:focus {
        overflow-y: overlay;
        &::-webkit-scrollbar-thumb {
            opacity: 1;
        }
    }

    // @media (max-width: 991.98px) {
    //     height: calc(100vh - 193px);
    // }
}

.chat-input-links{
    .list-inline-item:not(:last-child){
        margin: 0;
    }
}

.member-selector {
    position: absolute;
    left: 0;
    bottom: 100%;
    display: none;
    width: 14rem;
    background-color: #efefef;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.5rem;
        height: 2rem;
        border-bottom: 1px solid #e1e1e1;

        .icon {
            cursor: pointer;
        }
    }

    &-body {
        height: 12rem;
        margin-top: 0.5rem;
        overflow-y: overlay;
    }

    .member {
        display: flex;
        align-items: center;
        margin: 0.5rem;
        border-radius: 3px;
        padding: 2px 5px;

        .group-member-image {
            position: relative;
            margin-right: 1rem;
            .group-member-avatar {
                width: 2rem;
                height: 2rem;
                border-radius: 1rem;
            }
    
            .group-member-status {
                position: absolute;
                right: -1px;
                bottom: -1px;
                width: 0.8rem;
                height: 0.8rem;
                border: 2px solid $white;
                border-radius: 0.5rem;
                background-color: $gray-600;
    
                &.ONLINE {
                    background-color: $green;
                }
            }
        }

        &:hover, &.active {
            background-color: rgba($blue, 0.3);
        }
    }


    &.show {
        display: block;
    }

    .no-member {
        width: 100%;
        margin-top: 5rem;
        text-align: center;
        color: $gray-500;
    }
}
.respect-user-tooltip, .like-emoji-tooltip {
    .tooltip-inner {
        background-color: $gray-100;
        color: $gray-700;
    }
    .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .bs-tooltip-top .tooltip-arrow::before {
        border-top-color: $gray-100;
    }
}
.respect-user-wrap {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 10px;
    color: $gray-700;

    .respect-user {
        display: inline-block;
        width: 15px;
        height: 20px;
        margin: 10px 0;
        
        &:last-child {
            margin-right: 20px;
        }
    }
    .respect-user-avatar {
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }
}

.visitor-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    font-family: InterV;
    color: #7A7F9A;
    line-height: 25px;

    .nfts {
        width: 500px;
        height: 72px;
        overflow: hidden;

        .nft {
            width: 48px;
            height: 48px;
            margin: 12px;
            border-radius: 8px;
            box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
        }
    }
}

.no-message-tips {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    font-size: 28px;
    text-align: center;
    opacity: 0.6;
}

.animate-typing {
	.dot {
		display: inline-block;
		width: 4px;
		height: 4px;
		border-radius: 50%;
		margin-right: -1px;
		background: $white;
        animation: wave 1.3s linear infinite;
        opacity: 0.6;

		&:nth-child(2) {
			animation-delay: -1.1s;
		}

		&:nth-child(3) {
			animation-delay: -0.9s;
		}
	}
}

@keyframes wave {
	0%, 60%, 100% {
		transform: initial;
	}

	30% {
		transform: translateY(-5px);
	}
}

.group-image {
    background-size: contain;
}

.group-skeleton {
    display: flex;
    align-items: center;

    .ant-skeleton {
        margin-right: 1rem;
    }
}

.group-image-cover {
    background: linear-gradient(90deg, rgba($gray-100, 1) 0%, rgba($gray-100, 0.8) 56%, rgba($gray-100, 0.5) 100%);
}

.group-logo {
    width: 3.3rem;
    height: 3.3rem;
    border-radius: 0.5rem;
    overflow: hidden;
}
.group-logo-small {
    border-radius: 0.5rem;
    overflow: hidden;
}

.file-confirm-modal {
    .file-info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .image-info {
        text-align: center;
        img {
            max-width: 100%;
            max-height: 25rem;
        }
        .image-message {
            margin-top: 0.5rem;
        }
        .uploading {
            margin-bottom: 0.5rem;
        }
    }
}
.chat-input-box {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;

    .chat-input-quote {
        position: absolute;
        left: 0;
        top: -1.1rem;
        bottom: 100%;
        display: flex;
        align-items: center;
        width: 100%;
        height: 1.1rem;
        margin-bottom: 0.2rem;
        font-size: 12px;
        padding: 0 0.7rem;
        color: $gray-600;
        background-color: $gray-400;
        border-radius: 0.2rem;

        .chat-input-quote-msg {
            display: inline-block;
            width: calc(100% - 1rem);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        i {
            margin-left: auto;
            cursor: pointer;
        }
    }
}

#chat-alert {
    z-index: 9999;
    position: fixed;
    top: 1rem;
    right: 1rem;
}

.sticker-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 20rem;
    margin: 0.5rem;

    .sticker-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.5rem;
        height: 4.5rem;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        border: 1px solid $gray-400;
        border-radius: 0.2rem;
        cursor: pointer;

        img {
            max-width: 100%;
            max-height: 100%;
        }

        &:nth-child(4n) {
            margin: 0;
        }
    }
}

.chat-sticker {
    overflow: hidden;
    border-radius: 0.5rem;
    img {
        max-width: 10rem;
        max-height: 10rem;
    }
}
.chat-loading-wrap {
    position: relative;
}
.chat-loading {
    z-index: 9999;
    position: absolute;
    left: 50%;
    top: 0.2rem;
    transform: translateX(-50%);
    display: flex;
    align-items: center;

    .chat-loading-icon {
        animation: loading-360 2s infinite linear;
    }
    .chat-loading-text {
        padding-left: 0.2rem;
    }
}

.chat-unread-tips {
    z-index: 9999;
    position: absolute;
    left: 50%;
    top: 0.5rem;
    display: flex;
    transform: translateX(-50%);
    align-items: center;
    padding: 3px 0.5rem;
    border-radius: 0.6rem;
    background-color: $blue;
    color: $white;
    cursor: pointer;
    box-shadow: rgba($gray-700, 0.4) 0px 2px 8px 0px;

    .icon {
        font-size: 18px;
        padding-right: 0.2rem;
    }
}
.back-to-bottom {
    z-index: 999;
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
    display: flex;
    // transform: translateX(-50%);
    justify-content: center;
    align-items: center;
    padding: 3px 0.5rem;
    border-radius: 2rem;
    background-color: $blue;
    color: $white;
    cursor: pointer;
    box-shadow: rgba($gray-700, 0.4) 0px 2px 8px 0px;
    transition: all 0.5s ease;

    .icon {
        font-size: 18px;
    }
}
.no-more-messages {
    text-align: center;
    color: $gray-600;
    margin-bottom: 1.5rem;
}

.respect-confirm-modal {

    .modal-content {
        width: 332px;
        margin: 0 auto;
        background-image: url('../../../images/stickers/confirm_bg.png');
        background-repeat: no-repeat;
    }

    .modal-header {
        border: 0;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .gif {
            width: 180px;
        }
        .title {
            font-size: 18px;
            font-family: InterV_Medium;
            color: #495057;
            line-height: 21px;
            padding-bottom: 5px;
        }
        .desc {
            text-align: center;
            font-size: 12px;
            font-family: InterV;
            color: #7A7F9A;
            line-height: 15px;
        }
        .btn {
            width: 293px;
            height: 45px;
            margin: 1rem 0;
            background: #7269EF;
            border-radius: 4px;
            font-size: 15px;
            font-family: InterV;
            color: #FFFFFF;
            line-height: 19px;
            border: 1px solid #7269EF;
        }
        .no-confirm {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-family: InterV;
            color: #7A7F9A;
            line-height: 15px;
            cursor: pointer;

            .checkbox {
                margin: 0 0.25rem;
            }
        }
    }
}

.disconnect {
    position: absolute;
    left: 190px;
    bottom: 30px;
    background-color: $blue;
    transform: translateX(-50%);
    color: #fff;
    padding: 8px 16px;
    border-radius: 20px;
    display: flex;
    align-items: center;

    .chat-loading-icon {
        font-size: 16px;
        animation: loading-360 2s infinite linear;
    }
    .chat-loading-text {
        padding-left: 0.2rem;
    }
}

@keyframes loading-360 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}